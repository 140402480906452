import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bulk-promote"
export default class extends Controller {
  static targets = ["bulkCheckBox", "allCheckBox", 'typeId']
  selectedIds = null
  clicked(event) {
    if (event.target.value === "all" && this.allCheckBoxTarget.checked) {
      this.bulkCheckBoxTargets.forEach(checkbox => {
        checkbox.checked = true
      })
    } else if (event.target.value === "all" && !this.allCheckBoxTarget.checked) {
      this.bulkCheckBoxTargets.forEach(checkbox => {
        checkbox.checked = false
      })
    } else {
      this.allCheckBoxTarget.checked = false
    }
  }

  navigateToBulkPromote(event) {
    event.preventDefault()

    if (this.allCheckBoxTarget.checked) {
      this.selectedIds = ["all"]
    } else {
      this.selectedIds = this.bulkCheckBoxTargets
        .filter(checkbox => checkbox.checked)
        .map(checkbox => checkbox.value).join(",")
    }

    if (this.selectedIds.length > 0) {
      const basePath = event.currentTarget.getAttribute("href")
      const newPath = `${basePath}?bulk_ids=${this.selectedIds}&type_id=${event.target.dataset.bulkPromoteTypeId}`
      window.location.href = newPath
      // Turbo.visit(newPath)
    } else {
      alert("Please select at least one student.")
    }
  }

}