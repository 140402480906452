export default () => {
  let flashNotice = document.querySelector('.flash');
  const flashTurboFrame = document.getElementById('flash');

  const flashClose = () =>{
    if(flashNotice){
      flashNotice.style.opacity = '0';
      flashNotice.style.visibility = 'hidden';
      setTimeout(()=>{
        flashNotice.innerHTML = null;
      }, 1000)
    }
  }

  const flashTimeOut = setTimeout(flashClose, 5000);

  if(flashTurboFrame){
    flashTurboFrame.addEventListener('DOMNodeInserted', () => {
      setTimeout(()=>{
        flashNotice = document.querySelector('.flash');
        flashClose(flashTimeOut);
      }, 4000)
    }, false);
  }

  if(flashNotice){
    flashNotice.addEventListener('click', () => {
      flashClose();
    });
  };

};