import { Controller } from "@hotwired/stimulus"
import Pikaday from 'pikaday';

// Connects to data-controller="pikaday-init"
export default class extends Controller {

  connect() {
    this.element.autocomplete = 'off';

    const picker = new Pikaday({
      field: this.element,
      format: 'DD/MM/YYYY'
    });

  }
}