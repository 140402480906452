import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pw-show"
export default class extends Controller {
  static targets = ['eye', 'input']
  toggle() {
    this.eyeTarget.classList.toggle('show');    
    switch(this.inputTarget.type) {
      case "password":
        this.inputTarget.type = "text"
        break;
      case "text":
        this.inputTarget.type = "password"
        break;
      default:
        this.inputTarget.type = "password"
    }    
  }
}
