import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="costs-form"
export default class extends Controller {
  static values = { messageId: Number }
  static targets = ["clubEvent"]
  connect() {
  }

  clubValueChanged(event) {
    let params = new URLSearchParams()
    params.append("target", this.clubEventTarget.id)
    let clubId = event.target.selectedOptions[0].value

    console.log(clubId);

    get(`/api/clubs/${clubId}/events?${params}`, {
      responseKind: "turbo-stream"
    })
  }
}
