import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {

  static targets = ['cont']

  connect() {}

  toggle() {
    this.contTarget.classList.toggle('open');
  }
}
