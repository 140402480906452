import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox"
export default class extends Controller {
  connect() {

    let insertChecks = document.querySelectorAll('span.checkmark')
    if(insertChecks.length == 0){ 
      console.log("Yes")
      let checkboxes = this.element.querySelectorAll('input[type=checkbox]');
      const checkSpan = '<span class="checkmark"></span>';
      checkboxes.forEach(box => {
        box.insertAdjacentHTML('afterend', checkSpan);
      });    
    }
      
    let insertRadios = document.querySelectorAll('span.customradio')
    if(insertRadios.length == 0){
      let radios = this.element.querySelectorAll('input[type=radio]');
      const radioSpan = '<span class="customradio"></span>';
      radios.forEach(radio => {
        radio.insertAdjacentHTML('afterend', radioSpan);
      });
    }
    
  }
}
